.fancy-bullet {
  width: 0.5rem;
  height: 0.5rem;
  background-color: #008935;
  border-radius: 100%;
  margin-right: 1rem;
}

.fancy-bullet-connected::after {
  content: "";
  width: 2px;
  height: 16px;
  margin-top: 8px;
  margin-left: 3px;
  background-color: #e0e0e0;
  display: inline-block;
}

.h-full {
  height: 100%;
}
